import * as yup from "yup";
import { dateString, DATE_FORMAT } from "../common/yup/dateString";
import { DateTime } from "luxon";
import { CareType } from "../model/BookingDetails";

const contactSchema = () =>
    yup.object().shape({
        firstName: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^[a-zA-Z'][\sa-zA-Z-']*$/, "Invalid characters in first name"),
        lastName: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^[a-zA-Z'][\sa-zA-Z-']*$/, "Invalid characters in last name"),
        mobileNumber: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^04\d{8}$/, "Please enter a valid mobile number: 04xxxxxxxx (10 digits)"),
        emailAddress: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^.+@.+/, "Please enter a valid email address: example@example.com")
    });

const childSchema = () =>
    yup.object().shape({
        firstName: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^[a-zA-Z'][\sa-zA-Z-']*$/, "Invalid characters in first name"),
        lastName: yup
            .string()
            .trim()
            .required("This is a required field")
            .matches(/^[a-zA-Z'][\sa-zA-Z-']*$/, "Invalid characters in last name"),
        dob: dateString()
            .trimNull()
            .required("This is a required field")
    });

const bookingDetailsSchema = () =>
    yup.object().shape({
        preferredDays: preferredDaysSchema,
        fewerDays: yup.boolean(),
        startDate: dateString()
            .trimNull()
            .test("futureStartDate", "Please enter a future start date", MustBeFutureStartDate)
            .required("This is a required field")
    });

export const preferredDaysSchema = yup
    .object({
        [CareType.LDC]: yup.mixed().nullable(),
        [CareType.ASC]: yup.mixed().nullable(),
        [CareType.BSC]: yup.mixed().nullable()
    })
    .test("preferredDays", "Must have a care type", ValidateCareType);

function ValidateCareType(this: yup.TestContext, obj: any) {
    const { [CareType.LDC]: ldc, [CareType.ASC]: asc, [CareType.BSC]: bsc } = obj;

    if (ldc) {
        return (
            (AtLeastOnePreferredDay(ldc) && !asc && !bsc) ||
            this.createError({ message: "At least one day is required" })
        );
    }

    if (asc && bsc) {
        return (
            ((AtLeastOnePreferredDay(asc) || AtLeastOnePreferredDay(bsc)) && !ldc) ||
            this.createError({ message: "At least one day is required" })
        );
    }

    if (asc) {
        return (AtLeastOnePreferredDay(asc) && !ldc) || this.createError({ message: "At least one day is required" });
    }

    if (bsc) {
        return (AtLeastOnePreferredDay(bsc) && !ldc) || this.createError({ message: "At least one day is required" });
    }

    return this.createError({ message: "At least one day is required" });
}

const AtLeastOnePreferredDay = (obj: any) => obj.monday || obj.tuesday || obj.wednesday || obj.thursday || obj.friday;

function MustBeFutureStartDate(this: yup.TestContext, start: string) {
    if (!start) {
        return true;
    }
    const startDate = DateTime.fromFormat(start, DATE_FORMAT);
    const today = DateTime.local();
    return today.startOf("day") <= startDate.startOf("day");
}

export const schema = yup.object().shape({
    primaryContact: contactSchema(),
    child: childSchema(),
    booking: bookingDetailsSchema(),
    serviceId: yup
        .number()
        .nullable()
        .required("This is a required field"),
    token: yup
        .string()
        .nullable()
        .required("This is required")
});
