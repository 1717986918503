/* tslint:disable:no-submodule-imports */
import { mixed, TestOptionsMessage } from "yup";
// @ts-ignore
import yupLocale from "yup/lib/locale";
import { DateTime } from "luxon";

export const DATE_FORMAT = "yyyy-MM-dd";
const hasLength = (value: any) => value && value.length > 0;

export class DateStringSchema extends mixed {
    constructor() {
        super({ type: "datestring" });

        this.withMutation(() => {
            this.test(
                "datestring",
                // eslint-disable-next-line no-template-curly-in-string
                "${path} is not a valid date",
                (value: any) => {
                    return value == null || value === "" || DateTime.fromFormat(value as string, DATE_FORMAT).isValid;
                }
            );
        });
    }

    public max(max: Date | DateTime, message: TestOptionsMessage = yupLocale.date.max): DateStringSchema {
        const dateTime: DateTime = max instanceof Date ? DateTime.fromJSDate(max) : max;
        return this.test<{ max: string }>({
            name: "max",
            message,
            params: { max: dateTime.toISODate() },
            test: value => {
                return (
                    value == null || DateTime.fromFormat(value, DATE_FORMAT).startOf("day") <= dateTime.startOf("day")
                );
            }
        });
    }

    public min(min: Date | DateTime, message: TestOptionsMessage = yupLocale.date.min): DateStringSchema {
        const dateTime: DateTime = min instanceof Date ? DateTime.fromJSDate(min) : min;
        return this.test<{ min: string }>({
            name: "min",
            message,
            params: { min: dateTime.toISODate() },
            test: value => {
                return (
                    value == null || DateTime.fromFormat(value, DATE_FORMAT).startOf("day") >= dateTime.startOf("day")
                );
            }
        });
    }

    public required(message: string = yupLocale.mixed.required) {
        const next = super.required(message);
        return next.test({ message, name: "required", test: hasLength });
    }

    public trimNull() {
        return this.nullable().transform((value: any, original: any) => {
            const trimmed = value ? value.trim() : value;
            if (trimmed === "") {
                return null;
            }
            return trimmed;
        });
    }

    protected _typeCheck(value: any) {
        if (value instanceof String) {
            value = value.valueOf();
        }

        return typeof value === "string";
    }
}

export function dateString() {
    return new DateStringSchema();
}
