export enum CareType {
    LDC = "LDC",
    BSC = "BSC",
    ASC = "ASC"
}

export type BookingDays = {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
};

export type PreferredDays = {
    [CareType.LDC]: BookingDays;
    [CareType.ASC]: BookingDays;
    [CareType.BSC]: BookingDays;
};

export type BookingDetails = {
    startDate: Date;
    fewerDays: boolean;
    message: string;
    preferredDays: PreferredDays;
};
