import React from "react";
import { IServiceSectionProps } from "./ISectionProps";
import { ValidationMessage } from "./ValidationMessage";
import { BookingMessage } from "./BookingMessage";
import { PreferredDaysPicker } from "./PreferredDaysPicker";

export const BookingDetails: React.FC<IServiceSectionProps> = props => {
    const { errors, register, service, watch } = props;
    if (service === undefined) {
        const serviceId = watch("serviceId");
        if (serviceId) {
            return <ValidationMessage>Service does not exist</ValidationMessage>;
        } else {
            return null;
        }
    }

    return (
        <>
            <PreferredDaysPicker {...props} validCareTypes={service.careTypes} />

            <div className="w-full md:w-1/2 mt-6 md:mt-0 md:pr-3">
                <label className="block tracking-wide text-gray-700 text-base font-bold mb-3">
                    Preferred start date<span className="text-red-600">*</span>
                </label>
                <input
                    className={`field ${errors.booking?.startDate ? "field-error" : ""}`}
                    name="booking.startDate"
                    type="date"
                    placeholder="dd/mm/yyyy"
                    aria-label="start date"
                    ref={register}
                />
                {errors.booking?.startDate && (
                    <ValidationMessage>{errors.booking?.startDate.message}</ValidationMessage>
                )}
            </div>

            <BookingMessage {...props} />
        </>
    );
};
