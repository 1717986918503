import React from "react";
import { ISectionProps } from "./ISectionProps";

const maxMessageLength = 500;

export const BookingMessage: React.SFC<ISectionProps> = ({ register, setValue }) => {
    return (
        <div className="w-full mt-4">
            <label className="block  tracking-wide text-gray-700 text-base font-bold mb-3">
                Please advise if your child has any medical conditions or special care needs.
            </label>
            <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="booking.message"
                aria-label="booking message"
                maxLength={maxMessageLength}
                ref={register}
                onChange={e => {
                    const sanitizedText = e.target.value.replace(/(<([^>]+)>)/gi, "");
                    setValue && setValue("booking.message", sanitizedText);
                }}
            />
            <label className="tracking-wide text-gray-700 text-xs font-italic mb-3">Maximum 500 characters</label>
        </div>
    );
};
