import React from "react";
import { Checkbox } from "./Checkbox";

interface IBookingDayProps {
    label: string;
    name: string;
    onClick: () => void;
    register: () => void;
}

export const BookingDay: React.SFC<IBookingDayProps> = ({ label, name, onClick, register }) => {
    return (
        <label className="md:w-1/5 flex justify-start items-start">
            <div className="block text-gray-700 font-bold mb-2 md:mb-0">
                <Checkbox name={name} register={register} onClick={onClick} />
            </div>
            <span className="text-sm mr-2 font-normal">{label}</span>
        </label>
    );
};
