import React from "react";
import { ISectionProps } from "./ISectionProps";
import { ValidationMessage } from "./ValidationMessage";

export const ContactDetails: React.SFC<ISectionProps> = ({ errors, register }) => {
    return (
        <>
            <label className="w-full block tracking-wide text-gray-700 text-base font-bold mb-3">
                Contact name<span className="text-red-600">*</span>
            </label>

            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0 ">
                    <input
                        className={`field ${errors.primaryContact?.firstName ? "field-error" : ""}`}
                        name="primaryContact.firstName"
                        type="text"
                        placeholder="First name"
                        aria-label="first name"
                        maxLength={50}
                        ref={register}
                    />
                    {errors.primaryContact?.firstName && (
                        <ValidationMessage>{errors.primaryContact?.firstName.message}</ValidationMessage>
                    )}
                </div>

                <div className="w-full md:w-1/2 md:pl-3">
                    <input
                        className={`field ${errors.primaryContact?.lastName ? "field-error" : ""}`}
                        name="primaryContact.lastName"
                        type="text"
                        placeholder="Last name"
                        aria-label="last name"
                        maxLength={50}
                        ref={register}
                    />
                    {errors.primaryContact?.lastName && (
                        <ValidationMessage>{errors.primaryContact?.lastName.message}</ValidationMessage>
                    )}
                </div>
            </div>

            <div className="w-full mt-6">
                <label className="block  tracking-wide text-gray-700 text-base font-bold mb-3">
                    Email address<span className="text-red-600">*</span>
                </label>

                <input
                    className={`field ${errors.primaryContact?.emailAddress ? "field-error" : ""}`}
                    name="primaryContact.emailAddress"
                    type="text"
                    placeholder="example@email.com"
                    aria-label="email address"
                    maxLength={50}
                    ref={register}
                />
                {errors.primaryContact?.emailAddress && (
                    <ValidationMessage>{errors.primaryContact?.emailAddress.message}</ValidationMessage>
                )}
            </div>

            <div className="w-full mt-6">
                <label className="block tracking-wide text-gray-700 text-base font-bold mb-3">
                    Mobile number<span className="text-red-600">*</span>
                </label>
                <input
                    className={`field ${errors.primaryContact?.mobileNumber ? "field-error" : ""}`}
                    name="primaryContact.mobileNumber"
                    type="text"
                    placeholder="0400000000 (10 digits)"
                    aria-placeholder="exactly 10 digits"
                    aria-label="mobile number"
                    ref={register}
                />
                {errors.primaryContact?.mobileNumber && (
                    <ValidationMessage>{errors.primaryContact?.mobileNumber.message}</ValidationMessage>
                )}
            </div>
        </>
    );
};
