declare global {
    interface Window {
        appConfiguration: any;
    }
}

export interface IReCaptchaOptions {
    SiteKey: string;
}

export function GetReCaptchaOptions(): IReCaptchaOptions {
    return {
        SiteKey: window.appConfiguration?.ReCaptchaSiteKey || ""
    };
}
