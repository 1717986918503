import React from "react";
import { ISectionProps } from "./ISectionProps";
import { ValidationMessage } from "./ValidationMessage";

export const ChildDetails: React.SFC<ISectionProps> = ({ errors, register }) => {
    return (
        <>
            <label className="block tracking-wide text-gray-700 text-base font-bold mb-3">
                Child name<span className="text-red-600">*</span>
            </label>

            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0">
                    <input
                        className={`field ${errors.child?.firstName ? "field-error" : ""}`}
                        name="child.firstName"
                        type="text"
                        id="grid-first-name"
                        placeholder="First name"
                        aria-label="first name"
                        maxLength={50}
                        ref={register}
                    />
                    {errors.child?.firstName && (
                        <ValidationMessage>{errors.child?.firstName.message}</ValidationMessage>
                    )}
                </div>

                <div className="w-full md:w-1/2 md:pl-3">
                    <input
                        className={`field ${errors.child?.lastName ? "field-error" : ""}`}
                        name="child.lastName"
                        type="text"
                        id="grid-last-name"
                        placeholder="Last name"
                        aria-label="last name"
                        maxLength={50}
                        ref={register}
                    />
                    {errors.child?.lastName && <ValidationMessage>{errors.child?.lastName.message}</ValidationMessage>}
                </div>
            </div>

            <div className="w-full mt-6">
                <label className="block  tracking-wide text-gray-700 text-base font-bold mb-3">
                    Child date of birth (or expected date of birth for an unborn child)
                    <span className="text-red-600">*</span>
                </label>
            </div>

            <div className="w-full md:w-1/2 md:pr-3">
                <input
                    className={`field ${errors.child?.dob ? "field-error" : ""}`}
                    name="child.dob"
                    type="date"
                    placeholder="dd/mm/yyyy"
                    aria-label="date of birth"
                    ref={register}
                />
                {errors.child?.dob && <ValidationMessage>{errors.child?.dob.message}</ValidationMessage>}
            </div>
        </>
    );
};
