import React from "react";
import { CareType } from "../model/BookingDetails";
import { Radio } from "./Radio";

interface ICareTypePickerProps {
    careTypeGroup: string;
    setCareTypeGroup: (careType: string) => void;
    validCareTypes: CareType[];
}

const OnlyLDC = (validCareTypes: CareType[]) => validCareTypes.length === 1 && validCareTypes.includes(CareType.LDC);

const OnlyOSHC = (validCareTypes: CareType[]) => validCareTypes.length <= 2 && !validCareTypes.includes(CareType.LDC);

export const CareTypeGroupPicker: React.FC<ICareTypePickerProps> = ({
    careTypeGroup,
    setCareTypeGroup,
    validCareTypes
}) => (
    <>
        {!(OnlyLDC(validCareTypes) || OnlyOSHC(validCareTypes)) && (
            <label className="block tracking-wide text-gray-700 text-base font-bold">
                Care type<span className="text-red-600">*</span>
                <div className="grid mt-3" style={{ gridTemplateColumns: "auto auto auto" }}>
                    {validCareTypes.includes(CareType.LDC) && (
                        <Radio
                            name="careType"
                            value="LDC"
                            checked={careTypeGroup === "LDC"}
                            onChange={() => setCareTypeGroup("LDC")}
                        >
                            Long day care
                        </Radio>
                    )}
                    {(validCareTypes.includes(CareType.ASC) || validCareTypes.includes(CareType.BSC)) && (
                        <Radio
                            name="careType"
                            value="OSHC"
                            checked={careTypeGroup === "OSHC"}
                            onChange={() => setCareTypeGroup("OSHC")}
                        >
                            Before &amp; After school care
                        </Radio>
                    )}
                </div>
            </label>
        )}
    </>
);
