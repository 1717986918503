import React from "react";
import { EnquiryForm } from "./components/EnquiryForm";
import { Footer } from "./components/Footer";

export const App = () => (
    <div className="roboto h-screen flex flex-col">
        <main className="flex-auto">
            <p className="text-center text-4xl font-bold primary-green pt-4">Join Our Waitlist</p>

            <p className="text-center text-base px-1 pt-4">
                Please complete the form below with your details to join our waitlist.
                <br />
                We will review your enquiry and contact you shortly.
            </p>

            <EnquiryForm />
        </main>
        <Footer />
    </div>
);
