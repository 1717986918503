import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddToWaitlist } from "../api/WaitlistApi";
import { useModal, Modal } from "./Modal";
import { Details } from "../model/Details";
import { schema } from "./schema";
import { Guid } from "../common/guidUtils";
import ReCAPTCHA from "react-google-recaptcha";
import { GetReCaptchaOptions } from "./ReCaptchaOptions";
import { ValidationMessage } from "./ValidationMessage";
import { ChildDetails } from "./ChildDetails";
import { ContactDetails } from "./ContactDetails";
import { ErrorIcon } from "./ErrorIcon";
import { SuccessIcon } from "./SuccessIcon";
import { SubmitButton } from "./SubmitButton";
import { InProgressButton } from "./InProgressButton";
import { Section } from "./Section";
import { ServiceLoader } from "./ServiceLoader";
import { ServiceSelection } from "./ServiceSelection";
import { BookingDetails } from "./BookingDetails";

const SiteKey = GetReCaptchaOptions().SiteKey;

const getUniqueId = () => Guid.newGuid();

interface Fields {
    serviceId: number;
    token: string | null;
}

export type FormFields = Details & Fields;

export const EnquiryForm: React.SFC = () => {
    const props = useForm<FormFields>({
        mode: "onChange",
        validationSchema: schema
    });
    const { errors, formState, handleSubmit, register, reset, setValue } = props;

    // used to trigger component to re-render after form reset
    const [dateKey, setDateKey] = useState(Date.now());

    const idRef = useRef(getUniqueId());
    const captchaRef = useRef<ReCAPTCHA>(null);

    const successModal = useModal();
    const errorModal = useModal();

    useEffect(() => {
        register({ name: "serviceId" });
        register({ name: "token" });
    }, [register]);

    const resetForm = () => {
        captchaRef.current?.reset();
        reset();
        setDateKey(Date.now());
    };

    const onSubmit = async ({ serviceId, token, ...details }: FormFields) => {
        await AddToWaitlist(idRef.current, serviceId, token || "", details)
            .then(result => {
                if (result.isSuccess()) {
                    successModal.openModal();
                } else {
                    errorModal.openModal();
                }
            })
            .catch(e => {
                errorModal.openModal();
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit((data: FormFields) => onSubmit(data))} data-testid="enquiryform">
                <div className="container max-w-screen-sm mx-auto bg-white border border-gray-400 rounded-lg p-4 m-4">
                    <Section>
                        <Section.Title>Step 1. Contact Details</Section.Title>
                        <Section.Content data-testid="contact-details-section">
                            <ContactDetails {...props} />
                        </Section.Content>
                    </Section>
                    <Section>
                        <Section.Title>Step 2. Child Information</Section.Title>
                        <Section.Content data-testid="child-details-section">
                            <ChildDetails {...props} />
                        </Section.Content>
                    </Section>
                    <Section>
                        <Section.Title>Step 3. Booking Information</Section.Title>
                        <Section.Content data-testid="booking-details-section">
                            <ServiceLoader>
                                {services => (
                                    <ServiceSelection key={dateKey} {...props} services={services}>
                                        {service => <BookingDetails {...props} service={service} />}
                                    </ServiceSelection>
                                )}
                            </ServiceLoader>
                        </Section.Content>
                    </Section>

                    <div className="flex justify-center mb-6">
                        <div>
                            <div className="w-full mb-3">
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={SiteKey}
                                    onChange={val => setValue("token", val, true)}
                                />
                            </div>
                            {errors.token && <ValidationMessage>{errors.token?.message}</ValidationMessage>}
                        </div>
                    </div>

                    {formState.isSubmitting ? <InProgressButton /> : <SubmitButton />}
                </div>
            </form>

            {successModal.open && (
                <Modal duration={5} callback={resetForm} close={successModal.closeModal}>
                    <SuccessIcon />
                    <div className="pt-6 text-center text-xl">
                        Congratulations! Your waitlist request is being processed. A confirmation email has been sent to
                        you.
                    </div>
                </Modal>
            )}

            {errorModal.open && (
                <Modal close={errorModal.closeModal}>
                    <ErrorIcon />
                    <div className="pt-6 text-center text-xl">
                        Sorry, we are unable to process your request, please try again.
                    </div>
                </Modal>
            )}
        </>
    );
};
