import React from "react";

import { Content } from "./Content";
import { Title } from "./Title";

interface ISection {
    Title: React.SFC;
    Content: React.SFC;
}

const Section: React.SFC & ISection = ({ children }) => {
    return <div>{children}</div>;
};

Section.Title = Title;
Section.Content = Content;

export { Section };
