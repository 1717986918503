import React, { useEffect } from "react";
import { Service } from "../model/Service";
import { getServices } from "../api/ServicesApi";
import { ApiSuccess, ApiError } from "../api/ApiResult";
import { getQueryParams } from "../common/urlUtils";
import { IErrorResponse } from "../api/ErrorResponse";
import { ValidationMessage } from "./ValidationMessage";
import { InProgressMessage } from "./InProgressMessage";

interface IServiceLoaderProps {
    children: (services: Service[]) => React.ReactNode;
}

interface IFetchResult {
    data: Service[];
    isLoading: boolean;
    fetchError?: IErrorResponse;
}

const useFetch = (providerId: string | undefined): IFetchResult => {
    const [data, setData] = React.useState<Service[]>([]);
    const [fetchError, setFetchError] = React.useState<IErrorResponse>();
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getServices(providerId);
                if (res.isSuccess()) {
                    setData((res as ApiSuccess<Service[]>).success);
                } else {
                    setFetchError((res as ApiError<Service[]>).error);
                }
                setIsLoading(false);
            } catch (error) {
                setFetchError(error);
            }
        };

        fetchData();
    }, [providerId]);

    return { data, fetchError, isLoading };
};

export const ServiceLoader: React.SFC<IServiceLoaderProps> = ({ children }) => {
    const providerId = getQueryParams().providerId;
    const { data, fetchError, isLoading } = useFetch(providerId);

    if (providerId === undefined) {
        return <ValidationMessage>Provider does not exist</ValidationMessage>;
    }

    if (fetchError) {
        return <ValidationMessage>{fetchError.message}</ValidationMessage>;
    }

    if (isLoading) {
        return <InProgressMessage>Loading services</InProgressMessage>;
    }

    if (data.length === 0) {
        return <ValidationMessage>Service(s) do not exist</ValidationMessage>;
    }

    return <>{children(data)}</>;
};
