import Url from "url-parse";

export interface IQueryParams {
    providerId?: string;
    serviceId?: string;
    hmac?: string;
}

export const getQueryParams = (): IQueryParams => {
    var url = new Url(window.location.href, true);
    return {
        providerId: url.query["providerId"],
        serviceId: url.query["serviceId"],
        hmac: url.query["hmac"]
    };
};
