import React, { useEffect, useState } from "react";
import { ISectionProps } from "./ISectionProps";
import { ValidationMessage } from "./ValidationMessage";
import { PreferredDays } from "./PreferredDays";
import { CareType } from "../model/BookingDetails";
import { Checkbox } from "./Checkbox";
import { CareTypeGroupPicker } from "./CareTypeGroupPicker";

interface IPreferredDaysPicker extends ISectionProps {
    validCareTypes: CareType[];
}

export const PreferredDaysPicker: React.SFC<IPreferredDaysPicker> = props => {
    const { errors, register, triggerValidation, validCareTypes } = props;
    const [careTypeGroup, setCareTypeGroup] = useState("LDC");
    const defaultCareTypeGroup = validCareTypes.includes(CareType.LDC) ? "LDC" : "OSHC";

    useEffect(() => {
        setCareTypeGroup(defaultCareTypeGroup);
    }, [defaultCareTypeGroup, validCareTypes]);

    return (
        <div className="w-full mt-6 mb-4 md:mt-0 md:pr-3">
            <CareTypeGroupPicker
                careTypeGroup={careTypeGroup}
                setCareTypeGroup={setCareTypeGroup}
                validCareTypes={validCareTypes}
            />

            <label className="w-full block tracking-wide text-gray-700 text-base font-bold mt-4">
                Preferred days<span className="text-red-600">*</span>
                {careTypeGroup === "LDC" && <PreferredDays {...props} careType={CareType.LDC} />}
                {careTypeGroup === "OSHC" && (
                    <div className="mt-2">
                        {validCareTypes.includes(CareType.BSC) && (
                            <label className="text-sm mt-3">
                                Before school care
                                <PreferredDays {...props} careType={CareType.BSC} />
                            </label>
                        )}
                        {validCareTypes.includes(CareType.ASC) && (
                            <label className="text-sm mt-3">
                                After school care
                                <PreferredDays {...props} careType={CareType.ASC} />
                            </label>
                        )}
                    </div>
                )}
                {errors?.booking?.preferredDays && (
                    <ValidationMessage>{(errors.booking?.preferredDays as any).message}</ValidationMessage>
                )}
            </label>

            <div className="w-full mt-4">
                <label className="flex justify-start items-start">
                    <div className="block text-gray-700 font-bold mb-2 md:mb-0">
                        <Checkbox
                            name="booking.fewerDays"
                            register={register}
                            onClick={() => triggerValidation("booking.fewerDays")}
                        />
                    </div>
                    <span className="text-sm mr-2 text-gray-700">I will accept fewer days</span>
                </label>
            </div>
        </div>
    );
};
