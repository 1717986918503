import React from "react";
import logo from "./my-family-logo.png";
import { ExternalLink } from "./ExternalLink";

export const Footer = () => (
    <footer
        data-testid="footer"
        className="flex-shrink-0 w-full grid gap-1 md:gap-4 grid-cols-1 md:footer-layout items-center p-4 bg-gray-200"
    >
        <div className="flex justify-center md:justify-start items-center font-semibold text-gray-700">
            Waitlist&nbsp;powered&nbsp;by
            <ExternalLink href="https://qikkids.com.au/My-Family-Lounge/home">
                <img className="inline-flex w-32" alt="My Family Lounge" src={logo}></img>
            </ExternalLink>
        </div>
        <div className="grid md:grid-flow-col md:justify-end md:gap-8">
            <span className="footer-text">Copyright &copy; 2020 QK Technologies</span>
            <ExternalLink
                className="footer-text underline"
                href="https://qikkids.com.au/My-Family-Lounge/terms-conditions"
            >
                Terms &amp; Conditions
            </ExternalLink>
            <ExternalLink
                className="footer-text underline"
                href="https://qikkids.com.au/My-Family-Lounge/privacy-policy"
            >
                Privacy Policy
            </ExternalLink>
        </div>
    </footer>
);
