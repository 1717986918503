import { getApi } from "./apiFetchHelper";
import { ApiResult } from "./ApiResult";
import { Service, IServicesResponse } from "../model/Service";

const baseApi = "api/v1";
const controller = "services";

export const getServices = (providerId: string | undefined): Promise<ApiResult<Service[]>> => {
    const baseUrl = `${baseApi}/providers/${providerId}`;
    return getApi<IServicesResponse>(`${baseUrl}/${controller}`).then(response =>
        response.mapSuccess(success => success.services)
    );
};
