import React from "react";

export const ExternalLink: React.SFC<{
    className?: string;
    href: string;
}> = ({ className, href, children }) => (
    <a className={className} target="_blank" rel="noopener noreferrer" href={href}>
        {children}
    </a>
);
