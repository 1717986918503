import { postApi } from "./apiFetchHelper";
import { Details } from "../model/Details";
import { Guid } from "../common/guidUtils";
import { getQueryParams } from "../common/urlUtils";
import { ApiError } from "./ApiResult";

const baseApi = "api/v1";
const controller = "waitlist";

export const AddToWaitlist = (id: Guid, selectedServiceId: number, token: string, body: Details) => {
    let occurredOn = new Date().toISOString();
    const { providerId, serviceId, hmac } = getQueryParams();
    if (!providerId) {
        console.log(
            "did you mean to go to https://localhost:3000/?providerId=10621&serviceId=3&hmac=DE95E7198749479FE876A7F76FAF56EF9C8BD366BCBAB804D15C436B825026DD"
        );
        return Promise.resolve(new ApiError<void>(0));
    }

    let queryServiceId = serviceId;
    if (!queryServiceId) {
        queryServiceId = "-1";
    }

    const baseUrl = `${baseApi}/providers/${providerId}`;
    return postApi<void>(
        `${baseUrl}/${controller}?serviceId=${selectedServiceId}&id=${id}&occurredOn=${occurredOn}&token=${token}&queryServiceId=${queryServiceId}&hmac=${hmac}`,
        body
    );
};
