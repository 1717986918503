import React from "react";

const LoadSpinner: React.SFC = ({ children }) => (
    <div className="pr-2">
        <div className="spinner -ml-32"></div>
        <div className="inline-block pl-8">{children}</div>
    </div>
);

export const InProgressButton = () => (
    <button disabled={true} className="bg-gray-500 w-full md:w-40 text-white font-bold py-2 px-2 rounded text-lg">
        <LoadSpinner>Processing...</LoadSpinner>
    </button>
);
