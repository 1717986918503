import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

export const useModal = () => {
    const [open, onOpenModal] = useState(false);
    const [close, onCloseModal] = useState(false);

    const openModal = () => {
        onOpenModal(true);
    };

    const closeModal = () => {
        onCloseModal(true);
        onOpenModal(false);
    };

    return { open, close, openModal, closeModal };
};

const Portal: React.SFC = ({ children }) => {
    let modalRoot = document.getElementById("modal");
    if (!modalRoot) {
        modalRoot = document.createElement("div");
        modalRoot.setAttribute("id", "modal");
        document.body.appendChild(modalRoot);
    }
    const modalElement = document.createElement("div");
    useEffect(() => {
        modalRoot!.appendChild(modalElement);
        return () => {
            modalRoot!.removeChild(modalElement);
        };
    });
    return createPortal(children, modalElement);
};

type Props = {
    close: () => void;
    duration?: number;
    callback?: () => void;
};

export class Modal extends React.Component<Props> {
    private timer?: number;

    public render() {
        const { close, children, duration } = this.props;
        return (
            <Portal>
                <div className="roboto flex justify-center items-center fixed top-0 left-0 w-full h-full">
                    <div className="relative bg-white rounded-sm p-2 max-w-md z-10 shadow-lg mb-6">
                        <div className="flex flex-row-reverse h-4 w-full text-2xl">
                            {!duration && <i className="fas fa-times cursor-pointer" onClick={close}></i>}
                        </div>
                        <div className="pl-12 pr-12 pb-12">{children}</div>
                    </div>
                </div>
            </Portal>
        );
    }

    public componentWillUnmount() {
        clearTimeout(this.timer);
    }

    public componentDidMount() {
        const { duration, close, callback } = this.props;
        if (duration) {
            this.timer = window.setTimeout(() => {
                close();
                callback && callback();
            }, duration * 1000);
        }
    }
}
