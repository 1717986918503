import React, { useEffect, useState } from "react";
import Select, { IndicatorProps, StylesConfig } from "react-select";
import { getQueryParams } from "../common/urlUtils";
import { IServicesSectionProps, IServiceSectionProps } from "./ISectionProps";
import { ValidationMessage } from "./ValidationMessage";
import { findService } from "../common/arrayUtils";
import { Service } from "../model/Service";

const StaticService: React.FC<IServiceSectionProps> = ({ errors, service, setValue }) => {
    useEffect(() => {
        setValue("serviceId", service.serviceId);
    }, [service, setValue]);
    return <>{errors.serviceId && <ValidationMessage>{errors.serviceId.message}</ValidationMessage>}</>;
};

interface IServiceOption {
    label: string;
    id: number;
    value: Service;
}

const toOptions = (services: Service[]) => {
    return services.map(service => ({
        label: service.serviceName,
        id: service.serviceId,
        value: service
    }));
};

const HourglassIcon: React.SFC<IndicatorProps<IServiceOption>> = () => (
    <i className="fa fa-search text-gray-600 px-2"></i>
);

const servicePickerStyles: StylesConfig = {
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? "white" : "inherit",
        border: "none",
        boxShadow: "none"
    }),
    valueContainer: styles => ({ ...styles, padding: "12px" })
};

const ServicePicker: React.SFC<IServicesSectionProps> = props => {
    const { children, errors, services, setValue } = props;
    const [service, setService] = useState<Service>();

    useEffect(() => {
        service && setValue("serviceId", service.serviceId, true);
    }, [service, setValue]);

    return (
        <>
            <label label-for="service-picker" className="block tracking-wide text-gray-700 text-base font-bold mb-3">
                Find a centre<span className="text-red-600">*</span>
                <Select
                    id="service-picker"
                    className={`field px-0 py-0 ${errors.serviceId ? "field-error" : ""} mt-3 font-normal`}
                    options={toOptions(services)}
                    placeholder="Search by a centre name"
                    name="serviceId"
                    noOptionsMessage={() => "Sorry, there is no matching centre"}
                    onChange={val => {
                        const option = val as IServiceOption;
                        setService(option.value);
                    }}
                    components={{
                        DropdownIndicator: HourglassIcon,
                        IndicatorSeparator: () => null
                    }}
                    styles={servicePickerStyles}
                />
            </label>
            {errors.serviceId && <ValidationMessage>{errors.serviceId.message}</ValidationMessage>}
            {service && children(service)}
        </>
    );
};

export const ServiceSelection: React.SFC<IServicesSectionProps> = props => {
    const { children, services } = props;
    const staticServiceId = getQueryParams().serviceId;
    if (staticServiceId !== undefined) {
        const service = findService(services, Number(staticServiceId));
        if (service === undefined) {
            return <ValidationMessage>Service does not exist</ValidationMessage>;
        }
        return (
            <>
                <StaticService {...props} service={service} />
                {children(service)}
            </>
        );
    }

    return <ServicePicker {...props} />;
};
