import React from "react";

const Dot = () => (
    <svg data-testid="dot" className="fill-current hidden text-green-500 pointer-events-none" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="6" />
    </svg>
);

interface IRadioProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    checked: boolean;
    name: string;
    value: string;
}

export const Radio: React.FC<IRadioProps> = ({ onChange, checked, name, value, children }) => (
    <label className="grid" style={{ gridTemplateColumns: "2em auto" }}>
        <div
            className="bg-white border-2 rounded-full border-gray-400 focus-within:border-blue-500"
            style={{ width: "1.4em", height: "1.4em" }}
        >
            <input className="radio" type="radio" name={name} value={value} checked={checked} onChange={onChange} />
            <Dot />
        </div>
        {children}
    </label>
);
