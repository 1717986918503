import { ApiError, ApiResult, ApiSuccess } from "./ApiResult";
import { IErrorResponse } from "./ErrorResponse";

export function getApi<ResponsePayload>(url: string): Promise<ApiResult<ResponsePayload>> {
    return processResponseApi(fetch(url));
}

export function deleteApi<ResponsePayload>(url: string, body: object): Promise<ApiResult<ResponsePayload>> {
    return sendApi("DELETE", url, body);
}

export function postApi<ResponsePayload>(url: string, body: object): Promise<ApiResult<ResponsePayload>> {
    return sendApi("POST", url, body);
}

export function putApi<ResponsePayload>(url: string, body: object): Promise<ApiResult<ResponsePayload>> {
    return sendApi("PUT", url, body);
}

export function patchApi<ResponsePayload>(url: string, body: object): Promise<ApiResult<ResponsePayload>> {
    return sendApi("PATCH", url, body);
}

function sendApi<ResponsePayload>(method: string, url: string, body: object): Promise<ApiResult<ResponsePayload>> {
    let headers = {
        "content-type": "application/json"
    };

    return processResponseApi(
        fetch(url, {
            body: JSON.stringify(body),
            method,
            credentials: "same-origin",
            headers
        })
    );
}

function processResponseApi<ResponsePayload>(fetchResponse: Promise<Response>): Promise<ApiResult<ResponsePayload>> {
    return fetchResponse
        .then(response => {
            if (!response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType !== null && contentType.startsWith("application/json")) {
                    return ((response.json() as any) as Promise<IErrorResponse>).then(
                        error => new ApiError<ResponsePayload>(response.status, error)
                    );
                } else {
                    return new ApiError<ResponsePayload>(response.status);
                }
            }

            if (response.status === 201 || response.status === 202 || response.status === 204) {
                return Promise.resolve(new ApiSuccess<ResponsePayload>(response.status));
            }

            return (response.json() as Promise<ResponsePayload>).then(result => {
                return new ApiSuccess<ResponsePayload>(response.status, result) as ApiResult<ResponsePayload>;
            });
        })
        .catch(error => {
            throw error;
        });
}
