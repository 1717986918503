import React from "react";
import { ISectionProps } from "./ISectionProps";
import { BookingDay } from "./BookingDay";
import { CareType } from "../model/BookingDetails";

interface IPreferredDaysProps extends ISectionProps {
    careType: CareType;
}

export const PreferredDays: React.SFC<IPreferredDaysProps> = ({ careType, register, triggerValidation }) => {
    return (
        <div>
            <div className="flex flex-wrap md:mb-3">
                <div className="w-full md:flex mt-3">
                    <BookingDay
                        label="Mon"
                        name={`booking.preferredDays.${careType}.monday`}
                        onClick={() => triggerValidation("booking.preferredDays")}
                        register={register}
                    />
                    <BookingDay
                        label="Tue"
                        name={`booking.preferredDays.${careType}.tuesday`}
                        onClick={() => triggerValidation("booking.preferredDays")}
                        register={register}
                    />
                    <BookingDay
                        label="Wed"
                        name={`booking.preferredDays.${careType}.wednesday`}
                        onClick={() => triggerValidation("booking.preferredDays")}
                        register={register}
                    />
                    <BookingDay
                        label="Thu"
                        name={`booking.preferredDays.${careType}.thursday`}
                        onClick={() => triggerValidation("booking.preferredDays")}
                        register={register}
                    />
                    <BookingDay
                        label="Fri"
                        name={`booking.preferredDays.${careType}.friday`}
                        onClick={() => triggerValidation("booking.preferredDays")}
                        register={register}
                    />
                </div>
            </div>
        </div>
    );
};
