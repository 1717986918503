import React from "react";

const Tick = () => (
    <svg
        data-testid="tick"
        className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
        viewBox="0 0 20 20"
    >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
    </svg>
);

interface ICheckboxProps {
    onClick: () => void;
    register: () => void;
    name: string;
}

export const Checkbox: React.FC<ICheckboxProps> = ({ onClick, name, register }) => {
    return (
        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 mr-2 focus-within:border-gray-500">
            <input className="checkbox" name={name} type="checkbox" ref={register} onClick={onClick} />
            <Tick />
        </div>
    );
};
