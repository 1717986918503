import { Service } from "../model/Service";

export function findService(data: Service[], id: number) {
    var map = data.reduce(function(map: { [key: number]: Service }, service: Service) {
        map[service.serviceId] = service;
        return map;
    }, {});

    return map[id];
}
