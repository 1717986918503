import { IErrorResponse } from "./ErrorResponse";

export class ApiError<SuccessPayload> {
    public readonly error?: IErrorResponse;
    public readonly statusCode: number;

    constructor(status: number, error?: IErrorResponse) {
        this.error = error;
        this.statusCode = status;
    }

    public isSuccess(): this is ApiSuccess<SuccessPayload> {
        return false;
    }

    public mapSuccess<NewSuccessPayload>(
        mapper: (a: SuccessPayload) => NewSuccessPayload
    ): ApiResult<NewSuccessPayload> {
        return new ApiError<NewSuccessPayload>(this.statusCode, this.error);
    }
}

export type ApiResult<SuccessPayload> = ApiSuccess<SuccessPayload> | ApiError<SuccessPayload>;

export class ApiResponse<SuccessPayload> {
    public readonly statusCode: number;
    private readonly payload?: SuccessPayload;

    constructor(status: number, payload?: SuccessPayload) {
        this.payload = payload;
        this.statusCode = status;
    }

    public isSuccess(): this is ApiSuccess<SuccessPayload> {
        return true;
    }

    public get success() {
        if (!this.payload) {
            throw new Error("No payload found");
        }
        return this.payload;
    }
}

export class ApiSuccess<SuccessPayload> extends ApiResponse<SuccessPayload> {
    public mapSuccess<NewSuccessPayload>(
        mapper: (a: SuccessPayload) => NewSuccessPayload
    ): ApiResult<NewSuccessPayload> {
        return new ApiSuccess<NewSuccessPayload>(this.statusCode, mapper(this.success));
    }
}

export type ApiSuccessNoContent = ApiResult<void>;
